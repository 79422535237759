const shapePricingCardImages = require.context(
  "../../images/home/pricing",
  false,
  /\.(png|jpe?g|svg)$/
)

const featureIconAndImages = require.context(
  "../../images/home/pricing/engage-card",
  false,
  /\.(png|jpe?g|svg)$/
)

const engageCardContent = {
  mainLogo: shapePricingCardImages("./engage-card-logo.svg").default,
  heading: "Engage",
  subtext:
    "Get started with the most advanced employee experience platform for everyone.",
  price: ` <span style="font-size: 35px;">€</span><span style="font-size: 55px">1.97</span>`,
  pricingSubText: "Per employee/month*",
  buttonText: "Get Started",
  subHeading: "Fuel your Flourishing​",
  according: [
    {
      icon: featureIconAndImages("./onboarding-icon.svg").default,
      title: "Onboarding",
      picture: featureIconAndImages("./onboarding-picture.svg").default,
    },
    {
      icon: featureIconAndImages("./participation-support-icon.svg").default,
      title: "Participation Support",
      picture: featureIconAndImages("./participation-support-picture.svg")
        .default,
    },
    {
      icon: featureIconAndImages("./full-survey-icon.svg").default,
      title: "Full Survey",
      picture: featureIconAndImages("./full-survey-picture.svg").default,
    },
    {
      icon: featureIconAndImages("./full-personal-report-icon.svg").default,
      title: "Full Personal Report",
      picture: featureIconAndImages("./full-personal-report-picture.svg")
        .default,
    },
    {
      icon: featureIconAndImages("./personal-question-scores-icon.svg").default,
      title: "Personal Question Scores",
      picture: featureIconAndImages("./personal-question-scores-picture.svg")
        .default,
    },
    {
      icon: featureIconAndImages("./personal-best-practices-icon.svg").default,
      title: "50+ Personal Best Practices",
      picture: featureIconAndImages("./personal-best-practices-picture.svg")
        .default,
    },
    {
      icon: featureIconAndImages("./comparisons-with-team-company-icon.svg")
        .default,
      title: "Comparisons with Team/Company",
      picture: featureIconAndImages(
        "./comparisons-with-team-company-picture.svg"
      ).default,
    },
    {
      icon: featureIconAndImages("./personal-mental-health-indicator-icon.svg")
        .default,
      title: "Personal Mental Health Indicator",
      picture: featureIconAndImages(
        "./personal-mental-health-indicator-picture.svg"
      ).default,
    },
    {
      icon: featureIconAndImages("./persona-health-age-indicator-icon.svg")
        .default,
      title: "Personal Health Age Indicator",
      picture: featureIconAndImages(
        "./persona-health-age-indicator-picture.svg"
      ).default,
    },
    {
      icon: featureIconAndImages("./time-to-gain-indicator-icon.svg").default,
      title: "Time to Gain Indicator",
      picture: featureIconAndImages("./time-to-gain-indicator-picture.svg")
        .default,
    },
    {
      icon: featureIconAndImages("./enps-team-and-company-scores-icon.svg")
        .default,
      title: "eNPS Team & Company Scores",
      picture: featureIconAndImages(
        "./enps-team-and-company-scores-picture.svg"
      ).default,
    },
    {
      icon: featureIconAndImages("./work-from-home-personal-report-icon.svg")
        .default,
      title: "Work from Home Personal Report",
      picture: featureIconAndImages(
        "./work-from-home-personal-report-picture.svg"
      ).default,
    },
    {
      icon: featureIconAndImages("./demographic-headlines-for-execs-icon.svg")
        .default,
      title: "Demographic Headlines for Execs",
      picture: featureIconAndImages(
        "./demographic-headlines-for-execs-picture.svg"
      ).default,
    },
    {
      icon: featureIconAndImages("./diversity-breakdown-for-execs-icon.svg")
        .default,
      title: "Diversity Breakdown for Execs",
      picture: featureIconAndImages(
        "./diversity-breakdown-for-execs-picture.svg"
      ).default,
    },
    {
      icon: featureIconAndImages("./response-verbatims-report-icon.svg")
        .default,
      title: "Responses (Verbatims) Report",
      picture: featureIconAndImages("./response-verbatims-report-picture.svg")
        .default,
    },
    {
      icon: featureIconAndImages("./factsheets-for-everyone-icon.svg").default,
      title: "Factsheets for Everyone",
      picture: featureIconAndImages("./factsheets-for-everyone-picture.svg")
        .default,
    },
    {
      icon: featureIconAndImages("./single-sign-on-icon.svg").default,
      title: "Single Sign On (Optional)",
      picture: featureIconAndImages("./single-sign-on-picture.svg").default,
    },
  ],
}

export { engageCardContent }
