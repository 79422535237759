import React, { useEffect, useState } from "react"
import { pricingEmployees, pricingManagers } from "./helper"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import { Spinner } from "react-bootstrap"
import "./pricing-card.scss"
import Tick from "../../images/home/pricing/tick.svg"
import { Link } from "gatsby"
import axios from "axios"
const PricingCard = () => {
  let [currency, setCurrency] = useState(null)

  let tooltipElem = (content) => {
    return <div className={`pricingTooltipWrapper`}>{content}</div>
  }

  let currencyMap = {
    EUR: "€7", // Netherland
    USD: "$8", // US
    GBP: "£6", // London/UK
    AUD: "$10",
    CAD: "$9",
    NZD: "$11",
    ZAR: "$25",
    AED: "24 AED",
    PKR: "2000 PKR",
    INR: "540 INR",
    PHP: "370 PHP",
    MYR: "31 MYR",
    SAR: "25 SAR",
  }
  useEffect(() => {
    axios
      .get("https://ipapi.co/currency/")
      .then((res) => {
        console.log(res)
        setCurrency(res?.data)
      })
      .catch((err) => console.log(err))
  }, [])
  let isMobile =
    typeof window !== `undefined` &&
    window.matchMedia("(max-width: 786px)").matches
  let triggerArr = isMobile ? ["click", "focus"] : ["hover", "click"]
  return (
    <>
      <div className="pricingWrapper">
        <div className="pricingContentWrapper bordered">
          <div className="pricingContentHeadingBox">
            {currencyMap[currency] ? (
              <>
                <h1>
                  <span className="pricingAmount">{currencyMap[currency]}</span>{" "}
                  per employee{" "}
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 100 }}
                    overlay={tooltipElem("Billed annually")}
                    rootClose={true}
                    trigger={triggerArr}
                  >
                    <span className="pricingPerMonthElem"> per month</span>
                  </OverlayTrigger>
                </h1>
                <p>One simple price, no surprises</p>
              </>
            ) : (
              <Spinner animation="border" />
            )}
          </div>
          <h1 className="pricingHeadingManagers">Every Employee Gets </h1>
          <div className="pricingFeaturesWrapper">
            <div>
              {pricingEmployees?.map(
                (feature, index) =>
                  index <= 4 && (
                    <div className="pricingFeature">
                      <h4>
                        <img src={Tick} alt="Feature" />
                        {feature.heading}
                      </h4>
                      <p
                        dangerouslySetInnerHTML={{ __html: feature.subHeading }}
                      />
                    </div>
                  )
              )}
            </div>
            <div>
              {pricingEmployees?.map(
                (feature, index) =>
                  index >= 5 && (
                    <div className="pricingFeature">
                      <h4>
                        <img src={Tick} alt="Feature" />

                        {feature.heading}
                      </h4>
                      <p>{feature.subHeading}</p>
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="pricingWrapper managerPricingWrapper">
        <div className="pricingContentWrapper bordered contentManagers">
          <h1 className="pricingHeadingManagers">
            Even More for Managers and Executives
          </h1>
          <div className="pricingFeaturesWrapper">
            <div>
              {pricingManagers?.map(
                (feature, index) =>
                  index <= 4 && (
                    <div className="pricingFeature">
                      <h4>
                        <img src={Tick} alt="Feature" />

                        {feature.heading}
                      </h4>
                      <p>{feature.subHeading}</p>
                    </div>
                  )
              )}
            </div>
            <div>
              {pricingManagers?.map(
                (feature, index) =>
                  index >= 5 && (
                    <div className="pricingFeature">
                      <h4>
                        <img src={Tick} alt="Feature" />

                        {feature.heading === "SSO" ? (
                          <OverlayTrigger
                            placement="top-start"
                            delay={{ show: 250, hide: 100 }}
                            overlay={tooltipElem(
                              "Upon request, minimum volumes apply"
                            )}
                            rootClose={true}
                            trigger={triggerArr}
                          >
                            <span className="pricingFeatureHover">
                              {feature.heading}
                            </span>
                          </OverlayTrigger>
                        ) : (
                          feature.heading
                        )}
                      </h4>
                      <p>{feature.subHeading}</p>
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
        <Link className="pricing-cta-btn" to="/launch">
          Get SHAPE
        </Link>
        <div className="pricing-cta">
          <h4>
            Or contact us at{" "}
            <a href="mailto:sales@shapepowered.com" target="_blank">
              sales@shapepowered.com
            </a>
          </h4>
          <p> More than 1,000 employees? Get a discount.</p>
        </div>
      </div>
    </>
  )
}

export default PricingCard
