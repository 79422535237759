const pricingEmployees = [
  {
    heading: "In-take Survey",
    subHeading:
      'Across all <a href="/science/#explorer-view">12 Explorer topics</a>',
  },
  {
    heading: "Faster Flourishing Tips ",
    subHeading: "Accelerate up to 10x",
  },
  {
    heading: "Psychological Health Report",
    subHeading: "Signpost eligible staff to EAP",
  },
  {
    heading: "Time To Gain ",
    subHeading: "Time efficiency reporting",
  },

  {
    heading: "Health Age Report ",
    subHeading: "Improve physical health",
  },
  {
    heading: "Instant Employee Results",
    subHeading: "30+ Personal Reports",
  },
  {
    heading: "Best Practice Guidance ",
    subHeading: "60+ specialist topics + Factsheets",
  },
  {
    heading: "Custom Nudges ",
    subHeading: "Develop flourishing mindset",
  },

  {
    heading: "WFH Report",
    subHeading: "Optimise hybrid working",
  },

  {
    heading: "And Much More ",
    subHeading: "Comparisons, Topic Scorecards, Dashboards",
  },
]
const pricingManagers = [
  {
    heading: "Admin Dashboard",
    subHeading: "Self-manage your SHAPE experience",
  },

  {
    heading: "Team Reports For Managers",
    subHeading: "Exclusive guidance on performance",
  },
  {
    heading: "Executive Dashboard",
    subHeading: "Company wide reporting on flourishing",
  },
  {
    heading: "Diversity Analytics",
    subHeading: "Support DEI and Belonging Programs",
  },
  {
    heading: "Heat-Map",
    subHeading: "Real-time segment score analysis",
  },
  {
    heading: "Participation Analytics",
    subHeading: "Email templates, real-time metrics",
  },
  {
    heading: "eNPS Scores",
    subHeading: "Team and company loyalty scores",
  },

  {
    heading: "Productivity ROI Report",
    subHeading: "Claims, retention and absenteeism indicators",
  },

  {
    heading: "Free Text Response",
    subHeading: "Powerful sentiment analysis dashboard",
  },

  {
    heading: "SSO",
    subHeading: "Seamless experience, added security",
  },
]

const isString = (str) => {
  return typeof str === "string"
}

export { pricingEmployees, pricingManagers, isString }
