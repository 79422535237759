import React from "react"
import Layout from "../components/layout"
import PricingCard from "../components/pricing-card/pricing-card"
import SEO from "../components/seo"
import PricingCardNew from "../components/pricing-card/pricing-card-new"

const Pricing = () => {
  return (
    <Layout>
      <SEO title="Pricing" />
      <PricingCard />
      {/*}  <PricingCardNew /> */}
    </Layout>
  )
}

export default Pricing
